/*
 * File: _font.scss
 * Project: parkezdashboard
 * File Created: Thursday, 16th April 2020 4:10:29 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Thursday, 16th April 2020 4:20:06 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
 @font-face {
    font-family: 'NoirPro';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/NoirPro-Regular.woff2'); /* IE9 Compat Modes */
    src: local('Open Sans'), local('OpenSans'),
         url('../../assets/fonts/NoirPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/NoirPro-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/NoirPro-Regular.woff') format('woff'), /* Modern Browsers */
  }

  @font-face {
    font-family: 'NoirPro';
    font-style: bold;
    font-weight: 700;
    src: url('../../assets/fonts/NoirPro-Bold.woff2'); /* IE9 Compat Modes */
    src: local('NoirPro'), local('NoirPro'),
         url('../../assets/fonts/NoirPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/NoirPro-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/NoirPro-Bold.woff') format('woff'), /* Modern Browsers */
  }