/*
 * File: gmap.css
 * Project: parkezdashboard
 * File Created: Wednesday, 3rd June 2020 12:59:43 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Wednesday, 3rd June 2020 1:00:52 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * format google map elements
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

 .pac-container{
     z-index: 1500;
 }