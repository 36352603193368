.vehicle-card {
    display: inline-flex;
    margin: auto !important;
    padding-bottom: 10px;
    padding-left: 10px;
}
.type-icon {
    border: 2px solid #f9a825;
    border-radius: 20px;
    font-size: 40px !important;
    padding: 5px;
}
.icons{
    border-radius: 20px;
    font-size: 40px !important;
    padding: 5px;
}
.headericons{
    border-radius: 20px;
    font-size: 45px !important;
    padding: 10px;
}
.rupeeIcon{
    font-size: 20px !important;
    padding: 10px;
    padding-bottom: 5px;
}
.type-aurbis-icon{
    border: 2px solid rgb(49, 106, 255);
    border-radius: 20px;
    font-size: 40px !important;
    padding: 5px;
}
.type-text{
    margin: auto !important;
    margin-left: 17px !important;
    font-size: 20px !important;
    color: #000;
}
/* .parkingAvailabilityCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15%;
    background: linear-gradient(to bottom, rgb(113, 147, 235), transparent);
    transition: height 0.3s ease;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  } */
